<template>
  <div class="singleLed">
    <div class="banner">
      <div class="bannerBox">
        <div class="name">集中式LED调色控制器</div>
      </div>
    </div>
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="title">集中式LED调色控制器</div>
        <div class="paramTit">相关参数</div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品型号:</div>
              <div class="pasCont">LS-6RWGB</div>
            </div>

            <div class="li">
              <div class="pasName">额定输入:</div>
              <div class="pasCont">12V/24V（电压可选）</div>
            </div>
            <div class="li">
              <div class="pasName">调色通道:</div>
              <div class="pasCont">RGBW（红绿蓝白）/组</div>
            </div>
            <div class="li">
              <div class="pasName">调色组:</div>
              <div class="pasCont">6组</div>
            </div>
            <div class="li">
              <div class="pasName">额定输出:</div>
              <div class="pasCont">30W/组</div>
            </div>
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0°C-40°C</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">工作湿度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
            <div class="li">
              <div class="pasName">通信协议（可选）:</div>
              <div class="pasCont">RS-485</div>
            </div>
            <div class="li">
              <div class="pasName">通信协议（可选）:</div>
              <div class="pasCont">自定义LSSN2.0</div>
            </div>
            <div class="li">
              <div class="pasName">工作频段（可选）:</div>
              <div class="pasCont">433.0MHz~453.0MHz</div>
            </div>
            <div class="li">
              <div class="pasName">无线发射功率（可选）:</div>
              <div class="pasCont">＜10dBm</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div class="tx">集中式LED调色控制器是一个无线控制的彩色灯带控制装置，可驱动最多6条RGBW灯带，支持渐变式色彩调节功能，满足客房内不同区域氛围照明需求。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.banner {
  width: 100%;
  height: 6rem;
  background: url("../../../assets/product/singleLed.png");
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .name {
      display: block;
      text-align: left;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333333;
    }
  }
}
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding-bottom: 1.2rem;
    text-align: left;
    .title {
      padding: 0.39rem 0px;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      border-bottom: 1px solid #333;
    }
    .paramTit {
      margin-top: 0.38rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333;
    }
    .pas {
      margin-top: 0.79rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:nth-of-type(4) {
            .pasName {
              margin-right: 1.45rem;
            }
          }

          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 1.21rem;
          }
        }
      }
      .pasRight {
        .li {
          &:nth-of-type(1) {
            .pasName {
              margin-right: 2.41rem;
            }
          }
          &:nth-of-type(2) {
            .pasName {
              margin-right:1.45rem;
            }
          }
          &:nth-of-type(3) {
            .pasName {
              margin-right:1.43rem;
            }
          }
          &:last-of-type {
            .pasName {
              margin-right: 0.94rem;
            }
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
       font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
